




// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// const Benefits = () => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//     setPageNumber(1); // Reset to the first page
//   };

//   const goToPrevPage = () => {
//     if (pageNumber > 1) setPageNumber(pageNumber - 1);
//   };

//   const goToNextPage = () => {
//     if (pageNumber < numPages) setPageNumber(pageNumber + 1);
//   };

//   return (
//     <div className="container mx-auto px-4 mt-14">
//       <h2 className="text-2xl font-bold text-center my-4">Benefits</h2>
//       <div className="pdf-viewer flex flex-col items-center">
//         <div
//           style={{
//             width: '100%',
//             maxWidth: 400,
//             overflowX: 'hidden', // Hide horizontal scrollbar
//             overflowY: 'auto',  // Enable vertical scroll
//           }}
//         >
//           <Document
//             file="/pdf.pdf" // File path relative to the public folder
//             onLoadSuccess={onDocumentLoadSuccess}
//           >
//             <Page 
//               pageNumber={pageNumber} 
//               width={Math.min(window.innerWidth - 20, 380)} // Adjust width to ensure proper padding
//             />
//           </Document>

//           {/* Pagination controls */}
//           <div className="flex justify-between items-center mt-4">
//             <button
//               onClick={goToPrevPage}
//               disabled={pageNumber === 1}
//               className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
//             >
//               Previous
//             </button>
//             <p>
//               Page {pageNumber} of {numPages}
//             </p>
//             <button
//               onClick={goToNextPage}
//               disabled={pageNumber === numPages}
//               className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Benefits;





// import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// const Benefits = () => {
//   const [numPages, setNumPages] = useState(null);

//   // Function to handle document load success
//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   return (
//     <div className="container mx-auto px-4 mt-14">
//       <h2 className="text-2xl font-bold text-center my-4">Benefits</h2>
//       <div className="pdf-viewer flex flex-col items-center">
//         <div
//           style={{
//             width: '100%',
//             maxWidth: 400,
//             overflowY: 'auto',  // Enable vertical scrolling for all pages
//             height: 'calc(100vh - 140px)', // Adjust height for scrolling (full screen minus header)
//           }}
//         >
//           {/* Render the entire document */}
//           <Document
//             file="/pdf.pdf" // File path relative to the public folder
//             onLoadSuccess={onDocumentLoadSuccess}
//           >
//             {/* Render all pages in a stack */}
//             {Array.from(new Array(numPages), (_, index) => (
//               <div key={index}>
//                 <Page
//                   pageNumber={index + 1}
//                   width={Math.min(window.innerWidth - 20, 380)} // Adjust width to ensure proper padding
//                 />
//               </div>
//             ))}
//           </Document>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Benefits;







import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { FiShare2 } from 'react-icons/fi'; // Importing a share icon (using React Icons)

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Benefits = () => {
  const [numPages, setNumPages] = useState(null);

  // Function to handle document load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Share function using Web Share API
  const handleShare = async () => {
    const shareData = {
      title: 'Benefits PDF',
      text: 'Check out this PDF with all the benefits!',
      url: window.location.href, // Use the current page URL
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error('Error sharing the document:', err);
    }
  };

  return (
    <div className="container mx-auto px-4 mt-14">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-center my-4">Benefits</h2>
        
        {/* Share Button */}
        <button
          onClick={handleShare}
          className="text-blue-500 hover:text-blue-700"
          aria-label="Share PDF"
        >
          <FiShare2 size={24} /> {/* Share Icon */}
        </button>
      </div>

      <div className="pdf-viewer flex flex-col items-center">
        <div
          style={{
            width: '100%',
            maxWidth: 400,
            overflowY: 'auto',  // Enable vertical scrolling for all pages
            height: 'calc(100vh - 140px)', // Adjust height for scrolling (full screen minus header)
          }}
        >
          {/* Render the entire document */}
          <Document
            file="/pdf.pdf" // File path relative to the public folder
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {/* Render all pages in a stack */}
            {Array.from(new Array(numPages), (_, index) => (
              <div key={index}>
                <Page
                  pageNumber={index + 1}
                  width={Math.min(window.innerWidth - 20, 380)} // Adjust width to ensure proper padding
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default Benefits;


