
import { Route, Routes } from 'react-router-dom';
import LandingPage from 'pages/LandingPage';
import ProjectPage from 'pages/ProjectPage';
import NotFoundPage from 'pages/NotFoundPage';
import './assets/css/styles.css';
import Register from 'elements/Form/Register';
import Success from 'elements/Form/Success';
import Failed from 'elements/Form/Failed';
import Login from 'pages/LoginPage';
import Dashboard from 'pages/Dashboard';
import Header from 'parts/Header';
import ForgotPassword from 'pages/ForgotPassword';
import Benefits from 'pages/Benefits';

function App() {

  return (
    <>
      <Header/>
      <Routes>
        <Route exact path='/' element={<LandingPage/>} />

        <Route exact path='/login' element={<Login/>} />
        <Route exact path='/forgot-password' element={<ForgotPassword/>} />
        <Route exact path='/dashboard' element={<Dashboard/>} />
        <Route path='/prize-chart' element={<ProjectPage/>} />
        <Route path='/benefits' element={<ProjectPage/>} />
        <Route exact path='/register-now' element={<Register />} />
        <Route path='/payment/status/:transactionId' element={<Success/>} />
        {/* <Route path='/failure' element={<Failed/>} /> */}
        <Route path='**' element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
