
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../elements/Button";
import LogoutModal from "./LogoutModal"; // Import the modal component

export default function CustomHeader() {
  const [isCollapse, setIsCollapse] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const location = useLocation();
  const path = location.pathname;
  const token = localStorage.getItem('tokenfordossoclientuser');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    localStorage.removeItem('tokenfordossoclientuser');
    setIsModalOpen(false);
    // window.location.reload();
    navigate("/"); // Redirect to home page
  };

  return (
    <div className="fixed top-0 left-0 right-0 bg-white shadow-lg z-50">
      <div className="flex justify-between items-center px-0 lg:px-8 py-0">
        <Link to="/">
          <img src="/logo.png" alt="Brand Logo" className="h-20 w-auto mr-2" />
        </Link>

        <ul className="hidden lg:flex lg:items-center lg:justify-center mt-0 space-x-4 tracking-widest">
          <li>
            <Button
              className={`${path === "/" ? "active-link" : ""} font-medium text-lg no-underline hover:underline transition duration-200`}
              type="link"
              href="/"
            >
              Home
            </Button>
          </li>
          <li>
            <Button
              className={`${path === "/prize-chart" ? "active-link" : ""} font-medium text-lg no-underline hover:underline transition duration-200`}
              type="link"
              href="/benefits"
            >
              Benefits
            </Button>
          </li>
          {token ? (
            <>
              <li>
                <Button
                  className={`${path === "/dashboard" ? "active-link" : ""} font-medium text-lg no-underline hover:underline transition duration-200`}
                  type="link"
                  href="/dashboard"
                >
                  Dashboard
                </Button>
              </li>
              <li>
                <Button
                  className="font-medium text-lg px-6 py-2 bg-red-500 text-white rounded-full border-2 border-red-500 hover:bg-red-600 transition duration-200"
                  onClick={() => setIsModalOpen(true)} // Open modal on click
                >
                  Logout
                </Button>
              </li>
            </>
          ) : (
            <>
             <li onClick={() => setIsCollapse(!isCollapse)} className="py-1 bg-white">
              <Button
                className={`${path === "/" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
                type="link"
                href="/register-now"
              > 
                Register 
              </Button>
            </li>
              {/* <li>
                <Button
                  className="font-medium text-lg px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
                  type="link"
                  href="/register-now"
                >
                  Register Now 
                </Button>
              </li> */}
              {/* <li>
                <Button
                  className="font-medium text-lg px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
                  type="link"
                  href="/login"
                >
                  Login
                </Button>
              </li> */}
               <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
              <Button
                className={`${path === "/" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
                type="link"
                href="/login"
              > 
                Login
              </Button>
            </li>
            </>
          )}
        </ul>

        <button
          className="block  lg:hidden focus:outline-none"
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path className={`${isCollapse ? "hidden" : "block"}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            <path className={`${!isCollapse ? "hidden" : "block"}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <Transition
        show={isCollapse}
        enter="transition-opacity duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="transition duration-300 ease-in data-[closed]:opacity-0">
        <Fade>
  <ul className="z-50 h-60 space-y-4 flex flex-col text-theme-blue tracking-widest my-1 absolute bg-white w-full border-b-2 border-gray-300 lg:hidden">
    <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
      <Button
        className={`${path === "/" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
        type="link"
        href="/"
      > 
        Home 
      </Button>
    </li>
    <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
      <Button
        className={`${path === "/prize-chart" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
        type="link"
        href="/benefits"
      >
        Benefits
      </Button>
    </li>
    {token ? (
      <>
        <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
          <Button
            className={`${path === "/dashboard" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
            type="link"
            href="/dashboard"
          >
            Dashboard
          </Button>
        </li>
        <li onClick={() => setIsCollapse(!isCollapse)} className="mx-auto my-4 bg-white">
          <Button
            className="font-bold mx-auto px-5 py-2 bg-red-500 text-white rounded-full border-2 border-red-500 hover:bg-red-600 transition duration-200 ml-10"
            onClick={() => setIsModalOpen(true)} // Open modal on click
          >
            Logout
          </Button>
        </li>
      </>
    ) : (
      <ul className=" space-y-1 ">
        {/* <li onClick={() => setIsCollapse(!isCollapse)} className="mx-auto my-4 bg-white">
          <Button
            className="font-bold mx-auto px-5 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
            type="link"
            href="/register-now"
          >
            Register Now
          </Button>
        </li> */}
         <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
              <Button
                className={`${path === "/" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
                type="link"
                href="/register-now"
              > 
                Register
              </Button>
            </li>
        {/* Add custom margin for spacing */}
        {/* <li onClick={() => setIsCollapse(!isCollapse)} className="mx-auto my-4 bg-white">
          <Button
            className="font-bold mx-auto px-5 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200"
            type="link"
            href="/login"
          >
            Login
          </Button>
        </li> */}
         <li onClick={() => setIsCollapse(!isCollapse)} className="py-2 bg-white">
              <Button
                className={`${path === "/" ? "active-link" : ""} font-medium px-10 no-underline hover:underline`}
                type="link"
                href="/login"
              > 
                Login
              </Button>
            </li>
      </ul>
    )}
  </ul>
</Fade>

        </div>
      </Transition>

      {/* Logout Modal */}
      <LogoutModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onLogout={handleLogout}
      />
    </div>
  );
}




